@import '../../../config/variables';

.rowContainer {
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-bottom: 15px;
  border: 1px solid #acacac;
  border-radius: 4px;
  cursor: pointer;
  position: relative;
}
